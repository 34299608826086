import React, { useState, useEffect } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { useGooglePicker } from 'react-google-picker';

import { FaGoogleDrive } from "react-icons/fa";
import { axiosSecureInstance } from '../../api/axios';
import { getUserID } from '../../Utility/service';
import { createVectorOfKnowledgeBase } from '../../api/knowledgeBase';
import { getParentFolderNames } from './FileHelpers';
import { createKnowledgeBase } from '../../api/knowledgeBase';
import { GET_FILE_FROM_GOOGLE_DRIVE } from '../../constants/Api_constants';
import { message } from 'antd';


const GoogleFilePicker = ({ folderStructure, selectedFolder, setIsLoading,isLoading }) => {
    const [openPicker, data, authResponse] = useDrivePicker();
    const [filesInfo, setFilesInfo] = useState([]);

    const accessToken = process.env.REACT_APP_GOOGLE_DRIVE_ACCESS_KEY;
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    useEffect(() => {
        if (data) {
            handlePicked(data);

        }
    }, [data]);

    const handlePicked = async (data) => {
        setIsLoading(true);

        if (data.docs && data.docs.length > 0) {
            const files = data.docs.map(async (file) => {     
                const url = GET_FILE_FROM_GOOGLE_DRIVE(file.id,apiKey)
                const base64 = await fetchFile(url);

                return {
                    name: file.name,
                    size: file.sizeBytes,
                    type: 'file',
                    category :file.mimeType,
                    base64: base64
                };
            });
            const filesInfo = await Promise.all(files);
            await sendFileToServer(filesInfo);
            setFilesInfo(filesInfo);
        }
        setIsLoading(false);

    };

    const fetchFile = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                let errorMsg = `Failed to fetch file: ${response.statusText}`;
                if (response.status === 404) {
                  errorMsg = 'File not found. Please check the file ID and make sure the file is public.';
                } else if (response.status === 403) {
                  errorMsg = 'Access denied. Please ensure the file is shared publicly and check your API key permissions.';
                }
                throw new Error(errorMsg);
              }
            const blob = await response.blob();
            return convertToBase64(blob);
        } catch (error) {
            console.error("Error fetching file:", error);
        }
    };

    const convertToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result) {
                    resolve(reader.result);
                } else {
                    reject(new Error("Failed to convert blob to base64"));
                }
            };
            reader.onerror = (error) => {
                console.error("Error converting blob to base64:", error);
                reject(error);
            };
            reader.readAsDataURL(blob);
        });
    };

    const sendFileToServer = async (filesInfo) => {
        const previousParentFolderNames = getParentFolderNames(folderStructure, selectedFolder);
        const uploadedFileDetails = filesInfo.filter(file => file.base64).map(file => {
            const updatedFileName = previousParentFolderNames ? `${previousParentFolderNames}/${file.name}` : file.name;
            return { name: updatedFileName, size: file.size, type : file.category, base64: file.base64 };
        });
        const KnowledgeBase = {
            fileDetails: uploadedFileDetails,
            owner: getUserID()
        };

        try {
            if(uploadedFileDetails.length > 0){
                const response =  await createKnowledgeBase(KnowledgeBase);
                const preProcessForVectorEmbeddings = {
                    fileDetails: uploadedFileDetails,
                    userId: getUserID()
                };
                // const responseOfVectorCreate = await createVectorOfKnowledgeBase(preProcessForVectorEmbeddings);

                if(response.success){
                    const responseOfPreprocessing = await createVectorOfKnowledgeBase(preProcessForVectorEmbeddings);
                    if(responseOfPreprocessing.success){
                      message.success(response.message);
                    }else{
                      message.error(responseOfPreprocessing.message);
                    }
                    
                  }else{
                      message.error(response.message);
                  }
                
            }else{
                message.error("File could not be access. Use personal files only");
            }

        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleOpenPicker = () => {
        openPicker({
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            developerKey: apiKey,
            customScopes: ['https://www.googleapis.com/auth/drive.file','https://www.googleapis.com/auth/drive.readonly','https://www.googleapis.com/auth/drive.metadata.readonly'],
            viewId: "PDFS",
            customViews: ['MyDrive'],
            supportDrives: true,
            multiselect: true,
            onAuthenticate: () => console.log('Authentication successful'),
            onError: (error) => console.log('Error loading picker:', error),
            onChange: handlePicked,
            callbackFunction: (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button');
                }
                handlePicked(data);
            },
        });
    };

    return (
        <div>
            <li onClick={handleOpenPicker} > <FaGoogleDrive /> Import From Google Drive</li>
        </div>
    );
};

export default GoogleFilePicker;
