// SelectedFileContext.js
import React, { createContext, useState } from 'react';

export const FileContext = createContext();

export const FileContextProvider = ({ children }) => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [folderStructure, setFolderStructure] = useState([]);
  const [publicFilesStructure ,setPublicFilesStructure] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [deletedFileList,setDeletedFileList] = useState([]);
  return (
    <FileContext.Provider value={{
        selectedFile, setSelectedFile,
        folderStructure, setFolderStructure,
        selectedFolders, setSelectedFolders,
        fileList, setFileList,
        selectedRowKeys, setSelectedRowKeys,
        isModalVisible, setIsModalVisible,
        isLoading,setIsLoading,
        publicFilesStructure ,setPublicFilesStructure,
        deletedFileList,setDeletedFileList

        }}>
      {children}
    </FileContext.Provider>
  );
};
