export const AddAssistantTypeFormData = [
    {
        label: "Assistant Type",
        name: "assistantType",
        rules: [
            {
                required: true,
                message: 'Please Input Assistant Type!',
            },
        ]
    }
];