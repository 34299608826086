import { CgProfile } from "react-icons/cg";
import NavLinks from "../../Prompt/NavLink";
import { BsRobot } from "react-icons/bs";
import { FaTags } from "react-icons/fa6";
import { AiOutlineTeam } from "react-icons/ai";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import React, { useContext } from "react";
import { ThemeContext } from "../../../contexts/themeConfig";
import {AlignLeftOutlined}  from "@ant-design/icons"
import { GrTasks } from "react-icons/gr";

const SuperAdminNavLinks = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div>
      <NavLinks
        svg={
          <CgProfile
            size={22}
            style={{ color: theme === "light" ? "#000" : "#fff" }}
          />
        }
        text="My Profile"
        link="/profile"
      />
      <NavLinks
        svg={
          <svg
            width="1.5em"
            height="1.5em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
              stroke={theme === "light" ? "#000" : "#fff"}
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
              stroke={theme === "light" ? "#000" : "#fff"}
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
        text="Settings"
        link="/config"
      />



      <NavLinks
        svg={
          <FaMoneyBillTrendUp
            size={22}
            style={{ color: theme === "light" ? "#000" : "#fff" }}
          />
          
        }
        text="Usage"
        link="/trackUsage"
      />

     <NavLinks
        svg={
          <AlignLeftOutlined
            size={22}
            style={{ color: theme === "light" ? "#000" : "#fff" }}
          />
          
        }
        text="Assistant Types"
        link="/assistant-types"
      />
      {/*
                            // [NOTE: commenting for now, in future will be updated]   
                           <NavLinks
                              svg={
                                  <MdSubscriptions
                                      style={{
                                          color: "#FFFFFF",
                                          fontSize: "23px",
                                      }}
                                  />
                              }
                              text="Subscriptions"
                              link="/subscriptions"
                          /> */}
      <NavLinks
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            style={{ color: theme === "light" ? "#000" : "#fff" }}
            className="bi bi-file-earmark-bar-graph"
            viewBox="0 0 16 16"
          >
            <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
          </svg>
        }
        text="Users"
        link="/users"
      />
      {/*
                          // commenting for now, in future will be updated
                           <NavLinks
                              svg={
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="1.5em"
                                      height="1.5em"
                                      style={{ color: "white" }}
                                      className="bi bi-geo-alt"
                                      viewBox="0 0 16 16"
                                  >
                                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                  </svg>
                              }
                              text="Track Usage"
                              link="/trace"
                          /> */}

      <NavLinks
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            style={{ color: theme === "light" ? "#000" : "#fff" }}
            className="bi bi-card-list"
            viewBox="0 0 16 16"
          >
            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
          </svg>
        }
        text="Prompt Templates"
        link="/templist"
      />
      <NavLinks
        svg={
          <FaTags
            size={22}
            style={{ color: theme === "light" ? "#000" : "#fff" }}
          />
        }
        text="Tags"
        link="/tags"
      />
      <NavLinks
        svg={
          <AiOutlineTeam
            size={22}
            style={{ color: theme === "light" ? "#000" : "#fff" }}
          />
        }
        text="Teams"
        link="/teams"
      />
      <NavLinks
        svg={
          //   <svg
          //       xmlns="http://www.w3.org/2000/svg"
          //       width="1.5em"
          //       height="1.5em"
          //       style={{ color: "white" }}
          //       className="bi bi-card-list"
          //       viewBox="0 0 16 16"
          //   >
          //       <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
          //       <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
          //   </svg>
          <BsRobot
            size={22}
            style={{ color: theme === "light" ? "#000" : "#fff" }}
          />
        }
        text="Assistants"
        link="/assistantsList"
      />
      {/* [TODO : commenting for now, will be added later when organization functionality will be enabled ] */}
      {/* <NavLinks
                svg={<VscOrganization size={25} color="white"/>}
                text="Organizations"
                link="/organizations"
            /> */}

      <NavLinks
        svg={
          <GrTasks
            size={22}
            style={{ color: theme === "light" ? "#000" : "#fff" }}
          />
        }
        text="Task Commands"
        link="/taskCommands"
      />
    </div>
  );
};

export default SuperAdminNavLinks;
